<template>
	<div class="cont">
		<test></test>
		<img src="./images/banner2@1x.png" style="width: 100%;" >
		<div class="about">
			<img src="./images/about_03.png" />
			<p>山东国晟小额贷款有限责任公司（简称国晟普惠）是经中国人民银行及中国银行保险监督管理委员会审核，青岛市地方金融监督管理局批复成立的全省首家全国性（线上）网络小贷公司，总部设在青岛。<br>
			公司开创了山东省地方金融新业态，填补了互联网小贷行业发展空白。<br>
			我们始终践行普惠金融理念，通过产品创新和服务升级，为普惠用户提供一体化的金融服务。<br>
			</p>
		</div>
		<div class="a_sm">
			<div class="a_main">
				<div class="a_left">
					<ul>
						<li>
							<h3>企业使命</h3>
							<img src="./images/zp_18.png" >
							<p>为普惠用户提供一体化的金融服务</p>
						</li>
						<li>
							<h3>核心价值观</h3>
							<img src="./images/zp_18.png" >
							<p>以人为本 工匠精神 行稳致远</p>
						</li>
						<li>
							<h3>企业愿景</h3>
							<img src="./images/zp_18.png" >
							<p>做普惠用户最紧密的伙伴</p>
						</li>
					</ul>
				</div>
				<img src="./images/about_07.png" />
			</div>

		</div>
		<!-- <div class="team">
			<h3>管理团队</h3>
			<img src="./images/zp_18.png" >
			<ul>
				<li  v-for="(item,index) in list" :key="index">
					<img :src="item.contentImg" />
					<div class="t_txt">
						<h4>{{item.contentTitle}}</h4>
						<h6>{{item.contentDescription}}</h6>
						<p v-html="item.contentDetails"></p>
					</div>
				</li>

			</ul>
		</div> -->
		<foot></foot>
	</div>
</template>

<script>
import test from './components/test.vue'
import foot from './components/foot.vue'
export default {
	components: {
		test,
		foot
	},
	data(){
		return{
			list:[]
		}
	},
	created(){
		if (location.href.indexOf("#reloaded") == -1) {
			  location.href = location.href + "#reloaded";
			  location.reload();
		}
		var that = this
		this.$axios.post('/cms/returnAll/officialWebsite')
		.then(function (res) {
			console.log(res);
			 that.list = res.data.categoryList[8].contentList

		})
	}
}
</script>

<style>
	.cont p{
		font-size: 12px;
		color: #666;
		margin-top: 10px;
	}
	.Swindle .sw_div ul li .h_tit .kf{
		margin-right: 20px;
	}
	.Swindle .sw_div ul li .h_tit .left{
		padding-top: 20px;
		margin-left: 30px;
		flex: 6;
	}
	.Swindle .sw_div ul li .h_tit li img{
		flex: 0.2;
	}
	.Swindle .sw_div ul li .h_tit>h3{
		margin-bottom: 30px;
	}

	.Swindle .sw_div ul li .h_tit p{
		color: #fff;
		line-height: 2;
	}
	.Swindle .sw_div ul li h2{
		flex: 0.5;
		font-size: 30px;
		line-height: 50px;
	}
	.Swindle .sw_div ul li .h_tit{
		flex: 5;
		text-align: left;
		color: #fff;
	}
	.Swindle .sw_div ul li{
		margin-bottom: 30px;
		display: flex;
		justify-content: center;

	}
	.Swindle .sw_div{
		width: 65%;
		/* border: 1px solid #fff; */
		margin: 5% auto 0 auto;
		padding: 2% 0;
	}
	.Swindle>span{
		display: block;
		width: 40px;
		margin: 15px auto;
		height: 2px;
		background-color: #ea0029;
	}
	.Swindle h3{
		color: #fff;
	}
	.Swindle{
		background: url(./images/bg2@1x.png) no-repeat;
		background-size: 100% 100%;
		width: 100%;
		color: #fff;
		padding-top: 5%;
	}
	.product ul{
		overflow: hidden;
		width: 65%;
		margin: 20px auto;
	}
	.product ul li{
		width: 48%;
		height: 140px;
		margin: 1%;
		float: left;
		display: flex;
		justify-content: center;
	}
	.product ul li img{
		flex: 0.4;

	}
	.product ul li .r_txt h3{
		font-size: 16px;
	}
	.product ul li .r_txt{
		flex: 3.5;
		text-align: left;
		margin-left: 20px;
		padding-top: 20px;

	}
	.team ul{
		margin-top: 5%;
	}
	.team ul li .t_txt h4{
		font-size: 22px;
		margin-bottom: 20px;
	}
	.team ul li .t_txt p{
		font-size: 14px;
		margin-top: 20px;
	}
	.team ul li .t_txt h6{

	}
	.team ul li{
		margin-bottom: 5%;
		display: flex;
		justify-content: center;
		/* align-items: center; */
	}
	.team ul li .t_txt{
		flex:6;
		text-align: left;
		margin-left: 5%;
	}
	.team ul li img{
		width: 100px;
		height: 150px;
		margin-top: 54px;
	}
	.team{
		color: #333;
		padding: 3% 0;
		width: 65%;
		margin: 20px auto;
	}
	.a_main .a_left{
		flex: 3;
	}
	.a_main .a_left li>img{
		width: 60px;
	}
	.a_main .a_left li{
		margin-bottom: 15%;
		text-align: left;
	}
	.a_main .a_left li p{
		font-size: 12px;
		margin-top: 10px;
	}
	.a_main .a_left li h3{
		font-weight: normal;

	}
	.a_main>img{
		width: 70%;
	}
	.a_main{
		width: 65%;
		margin: 0 auto;
		display: flex;
		justify-content: center;
	}
	.a_sm{
		width: 100%;
		background-color: #f3f3f3;
		padding: 5% 0;
	}
	body{
		background-color: #fff;
	}
	.about{
		width: 800px;
		margin: 60px auto;
	}
	.about p{
		line-height: 2;
		margin-bottom: 20PX;
		font-size: 13PX;
	}
</style>
